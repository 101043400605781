var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"patientMatch-container"},[(_vm.show)?_c('div',{staticClass:"page"},[_c('div',{staticClass:"top"},[_c('patientItem',_vm._b({},'patientItem',{ 
					id: _vm.data.id, 
					name: _vm.data.name, 
					age: _vm.data.age,
					ill: _vm.data.illType, 
					sex: _vm.data.sex,
					status: _vm.data.status,
					type: 1
				},false))],1),_c('div',{staticClass:"match-hint"},[_vm._v("匹配到"+_vm._s(_vm.recordCount)+"个临床试验：")]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"dropdown-menu"},[_c('div',{staticClass:"dropdown-menu-item",on:{"click":function($event){_vm.isShowAarePicker = !_vm.isShowAarePicker}}},[_c('span',[_vm._v("地区")]),(!_vm.isShowAarePicker)?_c('van-icon',{attrs:{"name":"arrow-down","size":"12"}}):_c('van-icon',{attrs:{"name":"arrow-up","size":"12"}})],1),_c('div',{staticClass:"dropdown-menu-item",on:{"click":function($event){_vm.isShowStatusPicker = !_vm.isShowStatusPicker}}},[_c('span',[_vm._v("招募状态")]),(!_vm.isShowStatusPicker)?_c('van-icon',{attrs:{"name":"arrow-down","size":"12"}}):_c('van-icon',{attrs:{"name":"arrow-up","size":"12"}})],1)])]),_c('div',{staticClass:"search-result"},[_c('van-list',{attrs:{"immediate-check":false,"finished":_vm.finished,"finished-text":_vm.finishtext},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_vm._l((_vm.list),function(item,i){return [_c('projectItem',{key:i,attrs:{"projectInfo":item,"inquiryIdInfo":{inquiryId: _vm.inquiryId, inquiryName: _vm.data.name}}})]})],2)],1)]):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.isShowStatusPicker),callback:function ($$v) {_vm.isShowStatusPicker=$$v},expression:"isShowStatusPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.statusList},on:{"cancel":function($event){_vm.isShowStatusPicker = false},"confirm":_vm.statusConfirm}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.isShowAarePicker),callback:function ($$v) {_vm.isShowAarePicker=$$v},expression:"isShowAarePicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.columns},on:{"change":_vm.onChange,"cancel":function($event){_vm.isShowAarePicker = false},"confirm":_vm.areConfirm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }