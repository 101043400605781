<template>
	<div class="group">
		<div class="item-row">
			<div class="name">{{name}}</div>
			<div v-if="type==1" class="manage" @click="toPatientList">患者管理 </div>
			<!-- <div v-if="type==1"></div> -->
			<van-icon v-if="type==1" name="arrow" size="14"></van-icon>
		</div>
		<div class="info">{{sex ? sex == '男' ? '男 /' : '女 /' : '' }}  {{age ? `${age}/` : ''}}  {{ill}}</div>
		<div class="item-row">
			<img class="img-write" src="@assets/patient_write.png" />
			<div class="write" @click.stop="toPatientInfo">更新患者病情信息</div>
			<img v-if="type==0" class="img-delete" src="@assets/patient_delete.png" />
			<div v-if="type==0" @click.stop="toDelete()" class="delete">删除</div>
		</div>
		
	</div>
</template>

<script>
	import {
		Icon,
		Dialog,
		Toast
	} from 'vant';
	import patientApi from '@/api/patient/patientApi.js'
	import {toPath} from '@/utils/common'
	export default{
		components:{
			[Icon.name]:Icon,
		},
		props:{
			status:{
				type:Number,
				default : 0,
			},
			index:{
				type:Number,
				default : 0,
			},
			id:{
				type:Number,
				default : 0,
			},
			type:{
				type:Number,
				default : 0,
			},
			name: {
				type: String,
				default: ''
			},
			age: {
				type: String,
				default: ''
			},
			ill: {
				type: String,
				default: ''
			},
			sex: {
				type: String,
				default: ''
			},
		},
		methods:{
			toPatientList(){
				// this.$router.push({
				// 	path: '/patientList'
				// })
				toPath(this, {
					path:'/patientList',
					query:{} })
			},
			toPatientInfo(){
				// console.log('toPatientInfo')
				const {id, sex , name, ill, age , status } = this
				// this.$router.push({
				// 	path:'/patientInfo',
				// 	query:{
				// 		id,
				// 		sex ,
				// 		name ,
				// 		illType: ill,
				// 		age ,
				// 		status ,
				// 	}
				// })
				toPath(this, {
					path:'/patientInfo',
					query:{
						id,
						sex ,
						name ,
						illType: ill,
						age ,
						status ,
					}
				})
			},
			toDelete(){
				// console.log('toDelete')
				Dialog.confirm({
				  title: '删除',
				  message: '您确认要删除这位病患吗？'
				}).then(() => {
					patientApi.deleteInquiry({id:this.id}).then(rs=>{
						if(rs.status==1){
							this.$emit("delElem",this.index)						
							Toast({
								message: "删除成功"
							});
						}
					})
				}).catch(() => {
					Toast({
						message: "已取消"
					});
				});
				
			},
		}
	}
</script>

<style lang="less" scoped>
	.group{
		background-color: #FFFFFF;
		padding: 15px;
		margin-top: 8px;
		.item-row{
			display: flex;
			align-items: center;
			.name{
				flex: 1;
				width:auto;
				height:auto;
				font-size:16px;
				font-weight:500;
				color:rgba(42,42,46,1);
			}
			.manage{
				width:auto;
				height:auto;
				font-size:15px;
				font-weight:400;
				color:rgba(90,90,93,1);
				margin-right: 7px;
			}
			.write{
				flex: 1;
				width:auto;
				height:auto;
				font-size:14px;
				font-weight:400;
				color:rgba(0,96,194,1);
			}
			.delete{
				width:auto;
				height:auto;
				font-size:14px;
				font-weight:400;
				color:rgba(130,130,132,1);
			}
			.img-write{
				width: 12px;
				height: 12px;
				margin-right: 7px;
			}
			.img-delete{
				width: 15px;
				height: 15px;
				margin-right: 7px;
			}
			
		}
		.info{
			width:auto;
			height:auto;
			font-size:14px;
			font-weight:400;
			color:rgba(130,130,132,1);
			padding: 15px 0;
			border-bottom: #D5D5D5 solid 1px;
			margin-bottom: 15px;
		}
	}
	
	
</style>
