<template>
	<div class="patientMatch-container">
		<!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
			<div class="page" v-if="show">
				<div class="top">
					<patientItem 
					v-bind="{ 
						id: data.id, 
						name: data.name, 
						age: data.age,
						ill: data.illType, 
						sex: data.sex,
						status: data.status,
						type: 1
					}">
					</patientItem>
				</div>

				<div class="match-hint">匹配到{{recordCount}}个临床试验：</div>

				<div class="menu">
					<div class="dropdown-menu">
						<!-- <van-dropdown-menu class="vmenu">
							<van-dropdown-item class="menu-item" v-model="value1" :options="option1" />
							<van-dropdown-item class="menu-item" v-model="value2" :options="option2" />
						</van-dropdown-menu> -->
						<div class="dropdown-menu-item" @click="isShowAarePicker = !isShowAarePicker">
							<span>地区</span>
							<van-icon v-if="!isShowAarePicker" name="arrow-down" size="12" />
							<van-icon v-else name="arrow-up" size="12" />
						</div>
						<div class="dropdown-menu-item" @click="isShowStatusPicker = !isShowStatusPicker">
							<span>招募状态</span>
							<van-icon v-if="!isShowStatusPicker" name="arrow-down" size="12" />
							<van-icon v-else name="arrow-up" size="12" />
						</div>
					</div>
				</div>
				<div class="search-result">
					<van-list v-model="loading" :immediate-check="false" :finished="finished" :finished-text="finishtext" @load="onLoad">
						<template v-for="(item, i) in list">
							<projectItem :projectInfo="item" :inquiryIdInfo="{inquiryId, inquiryName: data.name}" :key="i" />
						</template>

					</van-list>

				</div>
			</div>
		<!-- </van-pull-refresh> -->
		<!-- 状态选择 -->
		<van-popup v-model="isShowStatusPicker" position="bottom">
			<van-picker show-toolbar :columns="statusList" @cancel="isShowStatusPicker = false" @confirm="statusConfirm" />
		</van-popup>
		<!-- 地区选择 -->
		<van-popup v-model="isShowAarePicker" position="bottom">
			<van-picker @change="onChange"  show-toolbar :columns="columns" @cancel="isShowAarePicker = false" @confirm="areConfirm" />
		</van-popup>
	</div>
</template>

<script>
	import {
		// PullRefresh,
		List,
		// DropdownMenu,
		// DropdownItem,
		Picker,
		Popup,
		Icon
	} from 'vant';
	import patientApi from '@/api/patient/patientApi.js';
	import projectItem from '@/components/project/project-item';
	import patientItem from '@/components/patient/patient-item';
	export default {
		components: {
			// [PullRefresh.name]: PullRefresh,
			[List.name]: List,
			// [DropdownMenu.name]: DropdownMenu,
			// [DropdownItem.name]: DropdownItem,
			[Icon.name]: Icon,
			[Picker.name]: Picker,
			[Popup.name]: Popup,
			projectItem,
			patientItem,
		},
		data() {
			return {
				inquiryId:this.$route.query.inquiryId,
				provinceId:null,
				cityId:null,
				status:null,
				data:null,
				show:false,
				// inquiryId:67,
				pageCount: 0,
				pageNo: 1,
				recordCount: 0,
				isShowProject: true,
				list: [],
				loading: false,
				finished: false,
				finishtext: '没有更多了',
				isLoading: false, //控制下拉刷新的加载动画
				// value1: 0,
				// value2: 'a',
				isShowStatusPicker: false, //控制招募弹框
				isShowAarePicker: false, //控制地区弹框
				statusList: [{
						text: '全部状态',
						val: ''
					},
					{
						text: '未开始',
						val: 0
					},
					{
						text: '招募中',
						val: 1
					},
					{
						text: '招募完成',
						val: 2
					},
				],
				columns: [

				],
				res: null,

			}
		},
		mounted() {
			this.initCity();
			this.getList();
		},
		methods: {
			
			initCity(inquiryId) {
				patientApi.matchArea({inquiryId:inquiryId}).then(rs=>{
					if(rs.status==1){
						this.res=rs.data;
						let province=Object.keys(this.res);
						this.columns = [{
								values: province,
								className: 'column1'
							},
							{
								values: this.res[province[0]].city,
								className: 'column2',
								defaultIndex: 2
							}
						]
					}
				})
				
			},
			statusConfirm(val, index) {
				
				console.log(val)
				this.status=val.val
				this.getList(); 
				this.isShowStatusPicker = false

			},
			areConfirm(val, index) {
				// console.log(val,index)
				this.provinceId=this.res[val[0]].provinceId;
				this.cityId=this.res[val[0]].cityId[index[1]];
				// console.log(this.provinceId,this.cityId)
				this.getList(); 
				this.isShowAarePicker=false;
			},
			onChange(picker, values) {
				// console.log(picker,values)
			    picker.setColumnValues(1, this.res[values[0]].city);
			     
			},
			// 改变状态选择
			statusChange() {},
			
			
			onLoad() {
				this.pageNo++;
				if((this.pageNo-1)>1&&this.pageNo*10>=this.recordCount){
					this.finished=true;	
				}
				this.loading=true;
				patientApi.matchMember({
					inquiryId:this.inquiryId,
					pageNo: this.pageNo,
					
				}).then(rs=>{
					let page=rs.data.pageList;
					this.loading=false;
					this.list=this.list.concat(rs.data.pageList.list);
					this.recordCount=page.recordCount;
					
				})
				
			},
			
			//获取列表
			getList() { //todo
				this.pageNo=1;
				patientApi.matchMember({
					inquiryId:this.inquiryId,
					pageNo: this.pageNo,
					provinceId: this.provinceId,
					cityId: this.cityId,
					status: this.status,
				}).then(rs=>{
					let page=rs.data.pageList;
					this.list=rs.data.pageList.list;
					this.recordCount=page.recordCount;
					this.data=rs.data.inquiry;
                    console.log("sex:"+this.data.sex)
					this.show=true;
					this.initCity(rs.data.inquiry.id)
				})
			},

			finishLoad(rs) {
				
				
			},


		}

	}
</script>

<style lang="less" scoped>
	.patientMatch-container {
		.page {
			min-height: 100vh;
			background-color: #F7F7F7;

			.top {
				padding-top: 8px;
			}

			.match-hint {
				padding: 15px 0 0 15px;
				width: auto;
				height: auto;
				font-size: 14px;
				font-weight: 400;
				color: rgba(130, 130, 132, 1);
			}

			.menu {
				width: 100%;
				height: auto;
				// display: flex;
				// flex-direction: row-reverse;
				padding-right: 15px;

				.dropdown-menu {
					width: 100%;
					display: flex;
					justify-content: flex-end;
					height: auto;

					.dropdown-menu-item {
						color: #313134;
						font-size: 15px;

						&:nth-of-type(1) {
							margin-right: 30px;
						}

						span {
							margin-right: 10px;
						}
					}

					// .vmenu {
					// 	background: transparent;
					// 	border-style: none;
					// 	height: 35px;

					// 	.menu-item {
					// 		border-bottom: transparent solid 0px;
					// 		border-top: transparent solid 0px;
					// 		border-width: 0;

					// 	}
					// }
				}

			}
		}

		.search-result {
			padding: 0px 15px 30px;


			.search-result-count {
				font-size: 14px;
				color: rgba(130, 130, 132, 1);
			}

		}

		.status-Picker {
			width: 100%;
			position: fixed;
			bottom: 0;
			left: 0;
		}
	}
</style>
